import { Box, Button } from "@chakra-ui/react";
import React, { memo, useEffect, useState } from "react";
import Logo from "../../../components/app/Logo";

const DownloadApp = memo(() => {
  const [deviceType, setDeviceType] = useState("desktop");

  const url =
    "https://beepbeep-s3.fra1.digitaloceanspaces.com/UsersAvatars/P20e02080-d248-4830-981f-ba32d5bd135d.apk?AWSAccessKeyId=L2EGEMXRTNANWZ7XO27X&Expires=253402288200&Signature=HxFgMQfPn1pqTl8xD7vxPE%2FVguI%3D";

  useEffect(() => {
    // Detect device type on component mount
    const userAgent = navigator.userAgent.toLowerCase();
    const isIOS = /iphone|ipad|ipod/.test(userAgent);
    const isAndroid = /android/.test(userAgent);

    if (isIOS) {
      setDeviceType("ios");
    } else if (isAndroid) {
      setDeviceType("android");
    }
  }, []);

  const handleDownload = () => {
    if (deviceType === "ios") {
      // For iOS, you might want to redirect to App Store
      alert(
        "iOS version is not available. Please use Android device or desktop."
      );
    } else if (deviceType === "android") {
      // For Android, create a hidden anchor element and trigger click
      const link = document.createElement("a");
      link.href = url;
      link.download = "app.apk";
      link.setAttribute("download", "");
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      // For desktop
      window.open(url, "_blank");
    }
  };

  return (
    <Box minWidth="100%" p="10" minHeight="100vh" bg="gray.50">
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Box className="logo-box">
          <Logo />
        </Box>
        <Button
          width="320px"
          height="56px"
          bg="#dc3545"
          color="white"
          borderRadius="full"
          fontSize="16px"
          fontWeight="500"
          _hover={{
            bg: "#c82333",
            transform: "translateY(-2px)",
            boxShadow: "0 4px 12px rgba(220, 53, 69, 0.3)",
          }}
          _active={{
            transform: "translateY(1px)",
            boxShadow: "0 2px 4px rgba(220, 53, 69, 0.2)",
          }}
          transition="all 0.3s ease"
          boxShadow="0 2px 4px rgba(220, 53, 69, 0.2)"
          onClick={handleDownload}
        >
          Download The Newest Version
        </Button>

        {/* Optional: Show different messages based on device type */}
        <Box mt={4} textAlign="center" color="gray.600" fontSize="sm">
          {deviceType === "ios" &&
            "iOS version is not available. Please use Android device or desktop."}
        </Box>
      </Box>
    </Box>
  );
});

export default DownloadApp;
